import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/seo';

import Layout from '../components/layout';
import SearchBar from '../components/searchbar';

const Index = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata.title;

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title="Homepage" />
            <section className="banner-1">
                <h1>
                    <span>Slovo</span> for <br />
                    Conjugations.
                </h1>
                <SearchBar />
            </section>
            <div className="banner-wave">
                <img
                    className="banner-wave__illustration"
                    src={require('../../content/assets/girl-with-L.webp')}
                    alt="The Girl with L"
                />
            </div>
        </Layout>
    );
};

export default Index;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
            edges {
                node {
                    excerpt
                    fields {
                        slug
                    }
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        description
                    }
                }
            }
        }
    }
`;
